import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import SecondaryButton from "../SecondaryButton";

const ProductVideoPlayer = forwardRef(({ videoUrl }, ref) => {
    const [videoIsPlaying, setVideoIsPlaying] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const mainVideoRef = useRef();
    const modalRef = useRef();

    const closeVideo = () => {
        setVideoIsPlaying(false);
        setIsModalOpen(false);
        mainVideoRef.current?.pause();
    };

    const toggleVideo = () => {
        if (videoIsPlaying) {
            setVideoIsPlaying(false);
            setIsModalOpen(false);
            mainVideoRef.current.pause();
        } else {
            setVideoIsPlaying(true);
            setIsModalOpen(true);
            mainVideoRef.current.play();
        }
    };

    // Expose functions via ref
    useImperativeHandle(ref, () => ({
        play: () => {
            setVideoIsPlaying(true);
            setIsModalOpen(true);
            mainVideoRef.current?.play();
        },
        pause: () => {
            setVideoIsPlaying(false);
            setIsModalOpen(false);
            mainVideoRef.current?.pause();
        },
        toggle: toggleVideo,
    }));

    useEffect(() => {
        const mainVideo = mainVideoRef.current;

        const handleMainVideoEnded = () => {
            closeVideo();
        };

        const handleEscapeKey = (event) => {
            if (event.key === "Escape" && isModalOpen) {
                closeVideo();
            }
        };

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeVideo();
            }
        };

        mainVideo.addEventListener("ended", handleMainVideoEnded);
        document.addEventListener("keydown", handleEscapeKey);
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            mainVideo.removeEventListener("ended", handleMainVideoEnded);
            document.removeEventListener("keydown", handleEscapeKey);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalOpen]);

    return (
        <>
            <div className="relative">
                <SecondaryButton
                    className="text-base sm:text-lg lg:text-xl py-3 sm:py-4 !px-10 ml-0 mt-4 !rounded-full  !z-50"
                    onClick={toggleVideo}
                >
                    Watch Video
                </SecondaryButton>
            </div>

            <div
                className={`fixed inset-0 bg-black bg-opacity-75 z-1000 flex items-center justify-center transition-all duration-300 ease-in-out ${
                    isModalOpen ? "opacity-100 visible" : "opacity-0 invisible"
                }`}
            >
                <div
                    className="w-[95vw] h-[80vh] relative xl:max-w-7xl"
                    ref={modalRef}
                >
                    <button
                        onClick={toggleVideo}
                        className="absolute top-20 right-4 text-white z-1000 p-2"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                    <video
                        ref={mainVideoRef}
                        className="w-full h-full object-contain z-1000"
                        src={videoUrl}
                        controls
                        playsInline
                    />
                </div>
            </div>
        </>
    );
});

export default ProductVideoPlayer;
