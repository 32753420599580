import { useState, useEffect } from "react";
import ApplicationLogo from "../components/ApplicationLogo";
import Header1 from "../components/Header1";
import ModalGettingStarted from "../components/UI/ModalGettingStarted";
import SecondaryButtonLink from "../components/SecondaryButtonLink";
import PrimaryButtonLink from "../components/PrimaryButtonLink";
import NavLinkLanding from "../components/NavLinkLanding";
import ResponsiveNavLink from "../components/ResponsiveNavLink";

import { Link } from "@inertiajs/react";
import { usePage } from "@inertiajs/react";

export default function LandingPageLayout({
    header,
    children,
    auth = null,
    openModalCreateAccount = null,
    setOpenModalCreateAccount = null,
    scrollToHowItWorks = null,
    scrollToFAQ = null,
}) {
    const { url } = usePage();

    const [isScrolled, setIsScrolled] = useState(false); // Add this state

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0); // Update state based on scroll position
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false);

    const handleClickHowItWorks = () => {
        if (scrollToHowItWorks) {
            scrollToHowItWorks();
        } else {
            window.location.href = "/#how-it-works";
        }
    };

    const handleClickFAQ = () => {
        if (scrollToFAQ) {
            scrollToFAQ();
        } else {
            window.location.href = "/#faq";
        }
    };
    return (
        <div className="min-h-screen flex flex-col grow max-w-screen-2xl mx-auto 2xl:border-x-2 2xl:border-[#FFFCF1] bg-gradient-to-b from-landing-page-blue-1 to-landing-page-blue-2">
            <nav
                className={`pt-4 pb-4 sticky top-0 bg-landing-page-blue-1 z-50 transition-shadow duration-300 z-2000 ${
                    isScrolled ? "shadow-lg" : "shadow-none"
                }`}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div className="shrink-0 flex items-center">
                                <Link href="/">
                                    <ApplicationLogo className="h-12 lg:h-[48px] xl:h-[52px] xl:h-[56px] w-auto" />
                                </Link>
                            </div>
                        </div>

                        <div className="hidden lg:flex lg:items-center lg:ml-6 text-sm lg:text-lg xl:text-xl">
                            <NavLinkLanding
                                href={route("about")}
                                active={route("about").endsWith(url)}
                            >
                                About Us
                            </NavLinkLanding>
                            <NavLinkLanding onClick={handleClickFAQ}>
                                FAQ
                            </NavLinkLanding>
                            <NavLinkLanding onClick={handleClickHowItWorks}>
                                How It Works
                            </NavLinkLanding>
                            <NavLinkLanding
                                href={route("pricing")}
                                active={route("pricing").endsWith(url)}
                            >
                                Pricing
                            </NavLinkLanding>
                            <NavLinkLanding
                                href={route("contact")}
                                active={route("contact").endsWith(url)}
                            >
                                Contact Us
                            </NavLinkLanding>
                        </div>

                        <div className="hidden sm:flex sm:items-center sm:ml-6">
                            {auth?.user ? (
                                <SecondaryButtonLink
                                    background="light"
                                    href={route("account.stories")}
                                    className="text-outragous-orange"
                                >
                                    My Account
                                </SecondaryButtonLink>
                            ) : (
                                <>
                                    <PrimaryButtonLink href={route("register")}>
                                        Create Account
                                    </PrimaryButtonLink>

                                    <SecondaryButtonLink
                                        background="light"
                                        href={route("login")}
                                        className="ml-4 text-outragous-orange"
                                    >
                                        Log In
                                    </SecondaryButtonLink>
                                </>
                            )}
                        </div>

                        <div className="-mr-2 flex items-center sm:hidden">
                            <button
                                onClick={() =>
                                    setShowingNavigationDropdown(
                                        (previousState) => !previousState
                                    )
                                }
                                className="inline-flex items-center justify-center p-2 rounded-md text-astronaut-blue hover:text-black focus:outline-none focus:text-black transition duration-150 ease-in-out"
                            >
                                <svg
                                    className="h-6 w-6"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        className={
                                            !showingNavigationDropdown
                                                ? "inline-flex"
                                                : "hidden"
                                        }
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={
                                            showingNavigationDropdown
                                                ? "inline-flex"
                                                : "hidden"
                                        }
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        (showingNavigationDropdown ? "block" : "hidden") +
                        " sm:hidden"
                    }
                >
                    <div className="pt-4 pb-1">
                        <div className="px-4">
                            <div className="space-y-1 pt-2 pb-3">
                                <ResponsiveNavLink
                                    href={route("about")}
                                    active={route("about").endsWith(url)}
                                >
                                    About Us
                                </ResponsiveNavLink>
                                <ResponsiveNavLink onClick={handleClickFAQ}>
                                    FAQ
                                </ResponsiveNavLink>
                                <ResponsiveNavLink
                                    onClick={handleClickHowItWorks}
                                >
                                    How It Works
                                </ResponsiveNavLink>
                                <ResponsiveNavLink
                                    href={route("pricing")}
                                    active={route("pricing").endsWith(url)}
                                >
                                    Pricing
                                </ResponsiveNavLink>
                                <ResponsiveNavLink
                                    href={route("contact")}
                                    active={route("contact").endsWith(url)}
                                >
                                    Contact Us
                                </ResponsiveNavLink>
                            </div>
                            <div className="flex items-center">
                                <div className="grow flex items-center">
                                    {auth?.user ? (
                                        <SecondaryButtonLink
                                            background="light"
                                            href={route("account.stories")}
                                            className="text-outragous-orange"
                                        >
                                            My account
                                        </SecondaryButtonLink>
                                    ) : (
                                        <>
                                            <PrimaryButtonLink
                                                href={route("register")}
                                            >
                                                Create Account
                                            </PrimaryButtonLink>
                                            <SecondaryButtonLink
                                                background="light"
                                                href={route("login")}
                                                className="ml-2 text-outragous-orange"
                                            >
                                                Log In
                                            </SecondaryButtonLink>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            {header && (
                <header className="shadow">
                    <Header1 className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                        {header}
                    </Header1>
                </header>
            )}

            <main className="text-astronaut-blue sm:mt-8 pt-8 overflow-hidden">
                {children}
            </main>

            {openModalCreateAccount !== null && (
                <ModalGettingStarted
                    isOpen={openModalCreateAccount}
                    setOpen={setOpenModalCreateAccount}
                />
            )}
        </div>
    );
}
